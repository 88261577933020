import { createMuiTheme, MuiThemeProvider, useTheme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { get, handleResponse, swal500 } from "../../utils/network";
import { SET_CLUB } from '../../utils/state/store';
import OnboardingComponent from "./OnboardingComponent";
import RedirectOnboardingComponent from './RedirectOnboardingComponent';

/*
    TODO: Esta listita deberia estar en el BE
*/

export const clubsDef = {
    ferro: {
        logoPath: '/assets/seed/images/escudo_ferro.png',
        theme: {
            palette: {
                primary: {
                    main: '#006534',
                },
                secondary: {
                    main: '#221E1F'
                }
            },
        }
    },
    harrods: {
        logoPath: '/assets/seed/images/escudo_harrods.png',
        theme: {
            palette: {
                primary: {
                    main: '#0093DD',
                },
                secondary: {
                    main: '#CB2C28'
                }
            },
        }
    },
    ccba: {
        logoPath: '/assets/seed/images/escudo_ciudad.png',
        theme: {
            palette: {
                primary: {
                    main: '#83B3DD',
                },
                secondary: {
                    main: '#FFFFFF'
                }
            },
        }
    },
    italiano: {
        logoPath: '/assets/seed/images/escudo_italiano.png',
        theme: {
            palette: {
                primary: {
                    main: '#E63027',
                },
                secondary: {
                    main: '#1F940C'
                }
            },
        }
    },
    cuba: {
        logoPath: '/assets/seed/images/escudo_cuba.png',
        theme: {
            palette: {
                primary: {
                    main: '#0093DD',
                },
                secondary: {
                    main: '#CB2C28'
                }
            },
        }
    }
};

const OnboardingContainer = (props) => {
    const [club, setClub] = useState('');
    const [clubs, setClubs] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const [clubSubdomainData, setClubSubdomainData] = useState({});
    const [clubSpecificTheme, setClubSpecificTheme] = useState(theme);
    const dispatch = useDispatch();
    const subdomain = window.location.hostname.split('.')[0];

    useEffect(() => {
        setClubSpecificTheme(theme);
    }, [theme]);

    useEffect(() => {
        dispatch({ type: SET_CLUB, club: null });
    }, []);

    useEffect(() => {
        const clubData = clubsDef[subdomain];

        if (!!clubData) {
            const clubStyle = createMuiTheme(
                {
                    ...theme,
                    ...clubData.theme
                }
            );
            setClubSpecificTheme(clubStyle)
            setClubSubdomainData(clubData)
            return;
        }

    }, []);

    useEffect(() => {
        let clubsCount, responseClubs = [];
        const getClubs = (page) => {
            setLoading(true);
            get(`onboarding?page=${page}&size=1000`)
                .then(res => handleResponse(res, props))
                .then(parsed => {
                    parsed.message.count && (clubsCount = parsed.message.count);
                    parsed.message.clubs.length && (responseClubs = responseClubs.concat(parsed.message.clubs));
                    setLoading(false);
                    if (responseClubs.length < clubsCount) {
                        getClubs(page + 1);
                    } else {
                        setClubs(responseClubs);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    swal500(err);
                });
        };
        getClubs(0);
    }, []);

    const handleChange = useCallback(
        (event) => {
            const { value } = event.target;
            setClub(value);
            setError(null);
        },
        [setClub, setError]
    );

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        if (!club) {
            setError('Ingrese un club')
            return;
        }
        const submittedClub = clubs.find(c => c.shortName === club);
        if (submittedClub) {
            dispatch({ type: SET_CLUB, club: submittedClub });
            props.history.push({
                pathname: '/login'
            });
        } else {
            setError('El club ingresado no está registrado')
        }
    }, [club, clubs, dispatch, setError]);


    return (
        <MuiThemeProvider theme={clubSpecificTheme}>
            {clubSubdomainData.logoPath ?
                <RedirectOnboardingComponent
                    clubData={clubSubdomainData}
                />
                :
                <OnboardingComponent
                    club={club}
                    error={error}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    loading={loading}
                />}
        </MuiThemeProvider>)

};

export default OnboardingContainer;